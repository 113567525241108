<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <PubhubControl> </PubhubControl>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PubhubControl from "@/components/pubhub/PubhubControl";

export default {
  components: {
    PubhubControl,
  },

  data() {
    return {};
  },

  beforeMount() {
    const options = {
      fromDate: "1 day",
      dataType: "source data",
    };

    this.$store.dispatch("pubhub/getCatalog", options);
  },
};
</script>

<style></style>
